import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { RouterModule } from '@angular/router';
import { TranslateNgxModule } from 'app/core/modules/translate-ngx.module';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    TranslateNgxModule,
    RouterModule
  ],
  exports:[
    FooterComponent
  ]
})
export class FooterModule { }
