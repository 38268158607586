import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { TranslateNgxModule } from 'app/core/modules/translate-ngx.module';

@NgModule({
  declarations: [LoaderComponent],
  imports: [
    CommonModule,
    TranslateNgxModule,
  ],
  exports:[
    LoaderComponent
  ]
})
export class LoaderModule { }
