const BASE_URL = "https://sandbox.eplalimo.com/";
const CBX_BASE_URL = "https://sandbox.eplalimo.com/";
// const CBX_BASE_URL = "http://192.168.1.13:5123/api/"
const LIM_BASE_URL = "https://limousines.coopgeneos.com/";

export const environment = {
  production: false,
  VERSION: "4.0.2023-1",
  cbx_productID: {
    southbound: {
      name: "Limousine - Single Trip Southbound 1P",
      code: "18000309",
    },
    northbound: {
      name: "Limousine - Single Trip Northbound 1P",
      code: "18000308",
    },
    round: {
      name: "Limousine - R1P Round Trip 1P",
      code: "18000317",
    },
  },
  NEW_CART: BASE_URL + "cart",
  NEW_ORDER: BASE_URL + "createOrder",
  MAIN_STOPS_URL: BASE_URL + "stops",
  RUNS_URL: BASE_URL + "trips",
  MAP_LOCATIONS_URL: BASE_URL + "/maps",
  SALE_TRIP_URL: BASE_URL + "/sale/trip",
  APP_CONFIG_URL: BASE_URL + "appConfig",
  SEATMAP_IFRAME_URL:
    "https://sandbox.betterez.com/operations/selectable-seatmaps/",
  AVAILABLE_PAYMENT_METHODS_URL: BASE_URL + "payment-methods",
  CONFIRM_PAYMENT_URL: BASE_URL + "process",
  GET_TICKETS_BY_TRX_ID_URL: BASE_URL + "tickets",
  SEND_EMAIL_URL: BASE_URL + "email",
  DELETE_CART_URL: BASE_URL + "deleteCart",
  CBX_TARIFA_URL: CBX_BASE_URL + "tafifas",
  CBX_TRANSACTION_URL: CBX_BASE_URL + "transaction",
  CBX_TRANSACTION_DETAIL_URL: CBX_BASE_URL + "transactionDetail",
  CBX_PAYMENT_URL: CBX_BASE_URL + "paymentDetails",
  X_API_KEY: "f7f91778-30b0-4d8f-96b5-4bb2febc7bb0",
  STRIPE_PUBLIC_KEY: "pk_test_x4pICdOGvy1o6g5m5OrFOIhI00l1fj63FQ",
  // LIMOUSINES
  LIM_ORIGINS_URL: LIM_BASE_URL + "station/stations/",
  LIM_DESTINATIONS_URL: LIM_BASE_URL + "route/destinations/?origin=",
  LIM_PASSENGERS_URL: LIM_BASE_URL + "ticket/ticket-types/",
  LIM_RESULTS_URL: LIM_BASE_URL + "route/trips/",
  LIM_SEATMAP_IFRAME_URL:
    "https://dinamicgeneos.ddns.net/operations/selectable-seatmaps/",
  // LIM_SEATMAP_IFRAME_URL:
  //   "https://dinamicgeneos.ddns.net/operations/selectable-seatmaps/",
  LIM_X_API_KEY: "85062f50-1cd8-4ef5-9d2c-e95c28d30191",
  LIM_OCCUPATION_URL: LIM_BASE_URL + "route/occupation/",
  LIM_RESERVE_URL: LIM_BASE_URL + "route/reserve/",
  LIM_CART_URL: LIM_BASE_URL + "route/cart/",
  LIM_CANCEL_URL: LIM_BASE_URL + "route/cancel/",
  LIM_ORDER_URL: LIM_BASE_URL + "route/order/",
  LIM_GET_TOKEN: LIM_BASE_URL + "admin/get-token/",
  LIM_TICKETS: LIM_BASE_URL,
};
