import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { LanguageService } from "@services/language.service";
@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  lang = "es";
  constructor(
    public renderer: Renderer2,
    public language: LanguageService){
  }
  ngOnInit(): void{
    this.lang = this.language.setLang();
    this.setHTMLScroll('hidden');
  }
  ngOnDestroy(): void{
    this.setHTMLScroll('auto');
  }
  setHTMLScroll(scroll_value: string): void{
    this.renderer.setStyle(document.getElementsByTagName('html')[0], 'overflow', scroll_value);
  }
}