import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@environments/environment';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  privacyNotice: any;
  termsConditions: any;
  contactEmail: any;
  USphone: any;
  MXphone: any;
  version: string = environment.VERSION;

  constructor(
    private cookieService: CookieService
    ) { }

  ngOnInit() {
    let _ = this;
    this.USphone = "866.691.9732";
    this.MXphone = "+52 614-742-0064";
    this.contactEmail = "eplasales@eplalimo.com"
  }

  public changeLang(lang: string) {
    this.cookieService.set("lang", lang);
    location.reload();
  }

}
