import { Component, HostListener } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "@services/language.service";
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: "navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent {
  menuResponsive: boolean = false;
  register: boolean = true;
  userData: boolean = false;
  nameUser: string = '';
  surnamesUser: string = '';
  openUserMenu: boolean = false;

  constructor(
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private language: LanguageService,
    private router: Router,
  ) {
    this.getParams();

    this.router.events.subscribe((val) => {
      this.menuResponsive = false;
    });
  }

 public changeLang(lang: string) {
    this.cookieService.set("lang", lang);
    location.reload();
  }

  @HostListener('document:click', ['$event'])
  closeHeaderMenuDOM(event) {
    if (event.target.getAttribute('menu_header_click')) {
      this.openUserMenu = !this.openUserMenu;
    } else {
      this.openUserMenu = false;
    }
  }

  getParams() {
    this.route.queryParams.subscribe(
      params => {
        if (params['lang'] !== null && params["lang"] !== undefined && params['lang'] !== '') {
          this.cookieService.set("lang", params["lang"]);
          this.translate.setDefaultLang(this.language.setLang());
        } else {
          this.translate.setDefaultLang(this.language.setLang());
        }

      }
    );
  }
}
