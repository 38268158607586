import {Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Renderer2} from "@angular/core";
import { AlertService } from "@services/alert.service";
import { LoaderService } from "@services/loader.service";
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

import * as moment from "moment";
import { LanguageService } from "@services/language.service";
moment.locale("es");
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  loader_active = false;
  alert_active: boolean = false;
  isProduction: boolean;
  lang = "es";
  loaded = false;
  constructor(
    private alertService: AlertService,
    public loaderService: LoaderService,
    private language: LanguageService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2, 
  ) {
    this.lang = this.language.setLang();
    this.isProduction = !environment.production;
    this.loadInfo();
  }
  
  ngOnInit() {
    
    this.loaded = false;
    this.alertService.current_is_active.subscribe(
      alert_status => (this.alert_active = alert_status)
    );

    this.loaderService.current_loading.subscribe(
      loader_status => (this.loader_active = loader_status)
    );

  }
  //No remover 
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  getAppConfig(){
    let _ = this;
    return this.http.get(environment.APP_CONFIG_URL);
  }

  loadInfo() {
    this.loaderService.openLoader();
    let _ = this;
    this.getAppConfig().subscribe(
      data => {
        const showliveChat = data['application'].settings.liveChat;

        localStorage.setItem("productId",data["application"].settings.productId);
        localStorage.setItem("generalFare",data["application"].settings.generalFare);
        localStorage.setItem('childFare',data['application'].settings.childFare);
        localStorage.setItem('providerId',data['application'].settings.providerId);
        localStorage.setItem("domain",data['application'].settings.domain);
        localStorage.setItem("isTesting",data['application'].settings.isTesting);

        //Operadoras disponibles: 
        localStorage.setItem("international", data['application'].settings.operators.international);
        localStorage.setItem("limousine-us", data['application'].settings.operators.limousine_us);
        localStorage.setItem("limousine-mx", data['application'].settings.operators.limousine_mx);
        localStorage.setItem("mexicoach", data['application'].settings.operators.mexicoach);

        console.log('appconfig...');
        console.log("dev_environment: " + this.isProduction);
        console.log("dev_config.... " + localStorage.getItem("isTesting"));
        console.log("domain: " + localStorage.getItem("domain"));
        console.log("live_chat_enable: " + showliveChat);
        console.log("active_operators: " + " " + localStorage.getItem("limousine-us") + " " + localStorage.getItem("limousine-mx") + " " + localStorage.getItem("international") + " " + localStorage.getItem("mexicoach"));
      
        this.loaderService.closeLoader();
        this.create3CX(showliveChat);
        this.cdr.detectChanges();
        this.loaded = true;
      },
      error => {
        this.loaderService.closeLoader();
        this.loaded = true;
      }
    );
  }
  create3CX(show:boolean){
    //Configuramos el componente de 3CX segun el idioma
    if(show){
      let party:string = "LiveChat210624";
      let id: string = "chat-en";
  
      if(this.lang == "es"){
        party = "LiveChat937828";
        id = "chat-es";
      }
  
      // Crear un nuevo elemento call-us-selector
      const newCallUsSelector = this.renderer.createElement('call-us-selector');
      // Establecer los atributos del nuevo elemento
      this.renderer.setAttribute(newCallUsSelector, 'id', id);
      this.renderer.setAttribute(newCallUsSelector, 'phonesystem-url', 'https://limo.3cx.us');
      this.renderer.setAttribute(newCallUsSelector, 'party', party);
      // Obtener la referencia al elemento body
      const body = document.body;
  
      // Insertar el nuevo call-us-selector antes del body
      this.renderer.insertBefore(body, newCallUsSelector, body.firstChild);
    }
    
  }

}