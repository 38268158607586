import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import es from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { NguCarouselModule } from '@ngu/carousel';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderModule } from '@global-components/loader/loader.module';
import { AlertModalModule } from '@global-components/alert-modal/alert-modal.module';
import { NavbarModule } from '@global-components/navbar/navbar.module';
import { FooterModule } from '@global-components/footer/footer.module';

registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NguCarouselModule,
    NgSelectModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    /* Layout */
    LoaderModule,
    NavbarModule,
    AlertModalModule,
    FooterModule,
  ],
  providers: [
    CookieService,
    {
      provide: LOCALE_ID,
      useValue: 'es-MX'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
