import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModalComponent } from './alert-modal.component';
import { LoaderModule } from '@global-components/loader/loader.module';
import { TranslateNgxModule } from 'app/core/modules/translate-ngx.module';

@NgModule({
  declarations: [AlertModalComponent],
  imports: [
    CommonModule,
    TranslateNgxModule,
    LoaderModule
  ],
  exports:[
    AlertModalComponent
  ]
})
export class AlertModalModule {}
