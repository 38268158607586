import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
const routes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  {
    path: "home",
    loadChildren: "@modules/home/home.module#HomeModule",
  },
  {
    path: "404-not-found",
    loadChildren: "@modules/404-not-found/not-found.module#NotFoundModule",
  },
  {
    path: "terminales",
    loadChildren: "@modules/terminals/terminals.module#TerminalsModule",
  },
  {
    path: "servicios",
    loadChildren: "@modules/abc-services/abc-services.module#AbcServicesModule",
  },
  {
    path: "conocenos",
    loadChildren: "@modules/about-us/about-us.module#AboutUsModule",
  },
  {
    path: "compra-de-boletos",
    loadChildren:
      "@modules/tickets-purchase/tickets-purchase.module#TicketsPurchaseModule",
  },
  {
    path: "compra-de-boletos-cbx",
    loadChildren:
      "@modules/CBX-tickets-purchase/cbx-tickets-purchase.module#CBXTicketsPurchaseModule",
  },
  {
    path: "compra-de-boletos-lim",
    loadChildren:
      "@modules/LIM-tickets-purchase/lim-tickets-purchase.module#LIMTicketsPurchaseModule",
  },
  {
    path: "policy",
    loadChildren: "@modules/policy/policy.module#PolicyModule",
  },
  { path: "**", redirectTo: "/404-not-found" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
