import { Component, OnInit } from '@angular/core';
import { AlertService } from '@services/alert.service';

@Component({
  selector: 'alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {
  msg: any;
  constructor(public alertService: AlertService) {}
  ngOnInit() {
    let msg;
    this.alertService.msg_error.subscribe(msg_error => msg = msg_error);
    this.msg = msg.split('|');
  }
  closeModal() {
    this.alertService.closeAlert();
  }

}
