import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root"
})
export class LanguageService {
  constructor(private cookieService: CookieService) {
  }

  public setLang(): string{
    let lang = "es";
    if(this.cookieService.get("lang") !== ""){
      lang = this.cookieService.get("lang");
    }else{
      this.cookieService.set("lang", "es");
      lang = this.cookieService.get("lang");
    }
    return lang;
  }
}
